import { useTheme } from '../../../../hooks';
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      bookmarkIcon: {
        height: '0.85em',
        marginLeft: '4px',
        width: '0.85em',
      },
      noFavoriteText: {
        display: 'flex',
      },
      noFavoriteTextContainer: {
        color: '#707070',
        fontSize: '14px',
        padding: '16px 32px',
      },
      seeAllButton: {
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '0.875rem',
        marginLeft: '20px',
        padding: '5px 0 5px 0',
      },
      seeAllButtonArrow: {
        marginTop: '2px',
      },
    },
    { index: 1 },
  )();
};
