import { Source } from '@outmind/types';
import React from 'react';

import { useAvailableSources } from '../../hooks/useAvailableSources';
import { GuardedRoute } from '../GuardedRoute';
import { AuthCallbackView } from './AuthCallbackView';
import { AuthStartView } from './AuthStartView';

export const SourcesRouter: React.FC = () => {
  const { sources } = useAvailableSources();
  const routes = sources
    .filter((source) => source !== Source.LocalDisk)
    .map((source) => (
      <>
        <GuardedRoute
          component={() => <AuthCallbackView source={source} />}
          path={`/sources/${source}/auth-callback`}
        />
        <GuardedRoute
          component={() => <AuthStartView source={source} />}
          path={`/sources/${source}/auth-start`}
        />
      </>
    ));

  return (
    <>
      {routes}
      <GuardedRoute
        component={() => <AuthCallbackView source="o365" />}
        path="/sources/o365/auth-callback"
      />
      <GuardedRoute
        component={() => <AuthStartView source="o365" />}
        path="/sources/o365/auth-start"
      />
      <GuardedRoute
        component={() => <AuthCallbackView source="gsuite" />}
        path="/sources/gsuite/auth-callback"
      />
      <GuardedRoute
        component={() => <AuthStartView source="gsuite" />}
        path="/sources/gsuite/auth-start"
      />
      <GuardedRoute
        component={() => <AuthCallbackView source={Source.LocalDisk} />}
        path="/sources/localdisk/auth-callback"
      />
    </>
  );
};
