/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      borderBox: {
        border: `1px solid ${theme.designSystem.colors.border.main}`,
        borderRadius: '10px',
        display: 'block',
        paddingTop: '10px',
        position: 'relative',
        width: '100%',
      },
      boxContainer: {
        display: 'flex',
        padding: '15px 20px',
      },
      boxTitle: {
        background: '#ffffff',
        color: '#666',
        fontSize: '0.875rem',
        left: '18px',
        padding: '0 10px 0 10px',
        position: 'absolute',
        top: '-12px',
      },
    },
    { index: 1 },
  )();
};
