import { makeStyles } from '../../material';

const bannerContainerBasic = {
  backgroundImage:
    'linear-gradient(-135deg, rgba(36,94,229,1) 0%, rgba(61,141,252,1) 50%, rgba(64,201,101,1) 100%)',
  display: 'flex' as const,
  flexDirection: 'column' as const,
  height: '100vh' as const,
  justifyContent: 'center' as const,
};
const MainWrapperBasic = {
  alignItems: 'center' as const,
  display: 'flex' as const,
  flexDirection: 'column' as const,
  height: '100%' as const,
};
const cardContentBasic = {
  alignItems: 'center' as const,
  backgroundColor: '#fafafa',
  display: 'flex' as const,
  flexDirection: 'column' as const,
  height: '100%',
  justifyContent: 'center' as const,
  width: '100%',
};

export const useStyles = makeStyles(
  {
    backgroundContainer: {
      height: '100vh',
      position: 'absolute',
      width: '100vw',
      zIndex: 0,
    },
    bannerContainer: bannerContainerBasic,
    bannerContainerMobile: {
      ...bannerContainerBasic,
      height: '25vh',
      minHeight: '150px',
    },
    cardContent: {
      ...cardContentBasic,
      '&:last-child': {
        paddingBottom: 0,
      },
      padding: 0,
      position: 'relative',
    },
    cardContentMobile: cardContentBasic,
    footerContainer: {
      bottom: 20,
      position: 'absolute',
    },
    footerContainerMobile: {
      paddingTop: '70px',
      position: 'relative',
    },
    mainWrapper: MainWrapperBasic,
    mainWrapperMobile: {
      ...MainWrapperBasic,
      minHeight: '75vh',
    },
  },
  { index: 1 },
);
