import { Blob } from '@outmind/helpers';
import React from 'react';

import { useSelector } from '../../store';
import { useStyles } from './styles';

/**
 * Displays the thumbnail of the ElasticDoc from its "thumbnail" property
 */
export const DocumentThumbnail: React.FC<DocumentThumbnailProps> = ({ thumbnail }) => {
  const classes = useStyles();
  const isPreviewOpened = useSelector((s) => s.previews.isOpened);

  const thumbnailBuffer =
    thumbnail && !thumbnail.includes('thumbnail') ? Blob.b64toBlob(thumbnail) : undefined;

  const thumbnailUrl = thumbnailBuffer ? URL.createObjectURL(thumbnailBuffer) : thumbnail;

  return thumbnailUrl && !isPreviewOpened ? (
    <div className={classes.thumbnailContainer}>
      <img alt="" className={classes.thumbnailImg} src={thumbnailUrl} />
    </div>
  ) : (
    <div className={classes.thumbnailPlaceholder} />
  );
};

interface DocumentThumbnailProps {
  thumbnail: string | undefined;
}
