import {
  ConnectorSyncState,
  ConnectorSyncStatus,
  ExtendedSources,
  LocalSources,
  Source,
} from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import useReactRouter from 'use-react-router';

import { Actions, useDispatch } from '../../../store';
import { ApiQuery, useApiRouteMaker } from '../../useApi';
import { FlowType } from '../../useFlowType';

/**
 * Hook used for creating a new connector based on the provided payload
 */
export const useCreateConnector = (): UseMutationResult<
  Response$CreateConnectorPayload,
  unknown,
  Params$CreateConnectorPayload
> => {
  const { history } = useReactRouter();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const makeCreateRoute = useApiRouteMaker(ApiQuery.CREATE_CONNECTOR);
  const makeBindRoute = useApiRouteMaker(ApiQuery.BIND_CONNECTOR);

  const createConnector = useMutation<
    Response$CreateConnectorPayload,
    unknown,
    Params$CreateConnectorPayload
  >(
    async ({ code, flow: _flow, source }) => {
      const route =
        source === Source.LocalDisk || source === Source.NetworkDisk
          ? makeBindRoute({ source })
          : makeCreateRoute({ source });

      const response = await axios({
        data: { code },
        method: route.method,
        params: {
          flow: _flow,
        },
        url: route.url,
        withCredentials: true,
      });

      return response.data;
    },
    {
      onError: () => {
        dispatch(Actions.notifyConnectorCreationFailed());
        history.push('/');
      },
      onSuccess: ({ connectorId }) => {
        const states = queryClient.getQueryData<Record<string, ConnectorSyncState>>([
          ApiQuery.GET_CONNECTOR_SYNC_STATE,
        ]);
        queryClient.setQueryData([ApiQuery.GET_CONNECTOR_SYNC_STATE], {
          ...states,
          [connectorId]: {
            ...states?.[connectorId],
            status: ConnectorSyncStatus.InProgress,
          },
        });
      },
    },
  );

  return createConnector;
};

/**
 * Hook used for creating a new connector based on the provided payload
 */
export const useCreateLocalDiskConnector = (): UseMutationResult<
  Response$CreateConnectorPayload,
  unknown,
  Params$CreateLocalDiskConnectorPayload
> => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const makeRoute = useApiRouteMaker(ApiQuery.CREATE_CONNECTOR);

  const createLocalDiskConnectorMutation = useMutation<
    Response$CreateConnectorPayload,
    unknown,
    Params$CreateLocalDiskConnectorPayload
  >(
    async (data) => {
      const route = makeRoute({ source: data.source });
      const response = await axios({
        data,
        method: route.method,
        url: route.url,
        withCredentials: true,
      });

      return response.data;
    },
    {
      onError: () => {
        dispatch(Actions.notifyConnectorCreationFailed());
      },
      onSuccess: ({ connectorId }) => {
        queryClient.invalidateQueries(ApiQuery.GET_MY_CONNECTORS);
        const states = queryClient.getQueryData<Record<string, ConnectorSyncState>>([
          ApiQuery.GET_CONNECTOR_SYNC_STATE,
        ]);
        queryClient.setQueryData([ApiQuery.GET_CONNECTOR_SYNC_STATE], {
          ...states,
          [connectorId]: {
            ...states?.[connectorId],
            status: ConnectorSyncStatus.InProgress,
          },
        });
      },
    },
  );

  return createLocalDiskConnectorMutation;
};

interface Params$CreateConnectorPayload {
  code: string;
  flow: FlowType;
  source: ExtendedSources;
}

interface Params$CreateLocalDiskConnectorPayload {
  folders: string[];
  machineId: string;
  name: string;
  source: LocalSources;
}

interface Response$CreateConnectorPayload {
  connectorId: string;
  source: ExtendedSources;
}
