import * as microsoftTeams from '@microsoft/teams-js';
import { ExtendedSources, Source } from '@outmind/types';
import * as queryString from 'query-string';
import React, { memo, useCallback, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';

import {
  FlowType,
  useConnectors,
  useCreateConnector,
  useTranslations,
  useUpdateCredentials,
  useUserProfile,
} from '../../hooks';
import { FullscreenLoader } from '../FullscreenLoader';

const AuthCallbackViewNP: React.FC<SourceCallbackViewProps> = ({ source }) => {
  const { history, location } = useReactRouter();

  const { t } = useTranslations();

  const { isSuccess: userProfileRetrieved } = useUserProfile();
  const { isSuccess: connectorsRetrieved } = useConnectors();

  const [code, setCode] = useState<string>();
  const [flow, setFlow] = useState<FlowType>('web');
  const [connectorCreated, setConnectorCreated] = useState(false);
  const [newConnectorId, setNewConnectorId] = useState<string>();
  const [connectorId, setConnectorId] = useState<string>();
  const [shouldUpdateCrendetials, setShouldUpdateCrendetials] = useState(false);

  const onSuccessRedirect = useCallback((): void => {
    const nextPath =
      source === Source.LocalDisk || source === Source.NetworkDisk
        ? ''
        : `?action=sync:${source}&connectorId=${newConnectorId}`;

    if (flow === 'deep') window.open(`outmind://${nextPath}`);
    else if (flow === 'teams') microsoftTeams.authentication.notifySuccess(nextPath);
    else return history.push(`/${nextPath}`);
  }, [newConnectorId, flow, history, source]);

  const { mutate: createConnector } = useCreateConnector();

  const { mutate: updateCredentials } = useUpdateCredentials();

  useEffect(() => {
    if (code && flow && shouldUpdateCrendetials && connectorId) {
      updateCredentials({ code, connectorId, flow, source: source as Source });
    }
  }, [shouldUpdateCrendetials, connectorId, source, code, flow]);

  useEffect(() => {
    if (code && flow && !shouldUpdateCrendetials) {
      createConnector(
        { code, flow, source },
        {
          onSuccess: ({ connectorId: _connectorId }) => {
            setConnectorCreated(true);
            setNewConnectorId(_connectorId);
          },
        },
      );
    }
  }, [shouldUpdateCrendetials, source, code, flow]);

  useEffect(() => {
    if (connectorCreated && userProfileRetrieved && connectorsRetrieved && newConnectorId) {
      onSuccessRedirect();
    }
  }, [
    connectorCreated,
    newConnectorId,
    connectorsRetrieved,
    onSuccessRedirect,
    userProfileRetrieved,
  ]);

  useEffect(() => {
    // Parse the code from the search query string in the location
    const search = queryString.parse(location.search);
    const hash = queryString.parse(location.hash);
    const path = location.pathname.split('/');
    const state = search['state'] ? JSON.parse(search['state'] as string) : undefined;
    if (state?.shouldUpdate) {
      setShouldUpdateCrendetials(true);
      setConnectorId(state.connectorId);
    }
    const queryCode = search['code'] ?? hash['token'] ?? hash['/token'];
    const queryFlow = search['flow'] ?? path[path.length - 1];
    if (typeof queryCode === 'string' && typeof queryFlow === 'string') {
      setCode(queryCode);
      setFlow(queryFlow as FlowType);
    } else if (!queryCode) {
      history.push('/');
    }
  }, [history, location.hash, location.pathname, location.search]);

  return (
    <FullscreenLoader
      actionButtonHelperText={t('go_back_to_outmind')}
      helperText={
        connectorCreated
          ? t('your_new_source_is_now_created')
          : t('creating_new_source_in_progress')
      }
      onButtonClick={() => onSuccessRedirect()}
      status={connectorCreated ? 'success' : 'loading'}
    />
  );
};

interface SourceCallbackViewProps {
  source: ExtendedSources;
}

export const AuthCallbackView = memo(AuthCallbackViewNP);
