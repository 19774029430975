import React from 'react';

import { Skeleton } from '../../material';
import { CollaboratorSkeleton } from './CollaboratorSkeleton';
import { MessagesSkeleton } from './MessageSkeleton';
import { PageSkeleton } from './PageSkeleton';
import { useStyles } from './styles';

/**
 * Renders the Document component when its inner data is still loading
 */
export const DocumentSkeleton: React.FC<DocumentSkeletonProps> = ({ bookmark, index }) => {
  const classes = useStyles();
  if (index === 'messages') {
    return <MessagesSkeleton />;
  }
  if (index === 'pages') return <PageSkeleton />;

  if (index === 'contacts' || index === 'people') return <CollaboratorSkeleton />;

  if (bookmark) {
    return (
      <div className={classes.bookmarkSkeletonContainer}>
        <div className={classes.skeletonTitleTypeLogo}>
          <Skeleton height={24} variant="circle" width={24} />
        </div>
        <div className={classes.skeletonTitleTypeLogo}>
          <Skeleton height={24} variant="circle" width={24} />
        </div>
        <div className={classes.skeletonTitleText}>
          <Skeleton variant="text" width={350} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.skeletonContainer}>
      <div className={classes.skeletonMain}>
        <div className={classes.skeletonTitle}>
          <div className={classes.skeletonTitleTypeLogo}>
            <Skeleton height={24} variant="circle" width={24} />
          </div>
          <div className={classes.skeletonTitleSourceLogo}>
            <Skeleton height={24} variant="circle" width={24} />
          </div>
          <div className={classes.skeletonTitleText}>
            <Skeleton variant="text" width={250} />
          </div>
        </div>
        <div className={classes.skeletonContent}>
          <Skeleton variant="text" width={400} />
          <Skeleton variant="text" width={300} />
        </div>
      </div>
      <div className={classes.skeletonThumbnail}>
        <Skeleton height={88} variant="rect" width={88} />
      </div>
    </div>
  );
};

interface DocumentSkeletonProps {
  bookmark?: boolean;
  index?: string;
}
