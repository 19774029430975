import { Source } from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import useReactRouter from 'use-react-router';
import { Actions, useDispatch } from '../../../store';
import { ApiQuery, useApiRouteMaker } from '../../useApi';
import { FlowType } from '../../useFlowType';
import { useStartSync } from '../../useStartSync';

/**
 * Hook used for updating a connector credentials
 */
export const useUpdateCredentials = (): UseMutationResult<
  Response$UpdateCredentialsPayload,
  unknown,
  Params$UpdateCredentialsPayload
> => {
  const { history } = useReactRouter();

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const { mutate: startSync } = useStartSync();

  const makeCreateRoute = useApiRouteMaker(ApiQuery.UPDATE_CREDENTIALS);

  const updateCredentials = useMutation<
    Response$UpdateCredentialsPayload,
    unknown,
    Params$UpdateCredentialsPayload
  >(
    async ({ code, flow: _flow, source, connectorId }) => {
      const route = makeCreateRoute({ connectorId, source });

      const response = await axios({
        data: { code },
        method: route.method,
        params: {
          flow: _flow,
        },
        url: route.url,
        withCredentials: true,
      });

      return response.data;
    },
    {
      onError: (_err, { source }) => {
        dispatch(Actions.notifyCredentialsUpdateFailed(source));
        history.push('/');
      },
      onSuccess: ({ connectorId, source }) => {
        history.push('/');
        dispatch(Actions.notifyCredentialsUpdateSuccess(source));
        queryClient.invalidateQueries(ApiQuery.GET_MY_CONNECTORS);
        startSync({ connectorId, source });
      },
    },
  );

  return updateCredentials;
};

interface Params$UpdateCredentialsPayload {
  code: string;
  connectorId: string;
  flow: FlowType;
  source: Source;
}

interface Response$UpdateCredentialsPayload {
  connectorId: string;
  source: Source;
}
