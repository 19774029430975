import React from 'react';
import Media from 'react-media';
import { Card, CardContent, Grid } from '../../material';
import { Footer } from '../Footer';
import { OutmindLogo } from '../OutmindLogo';
import { useStyles } from './styles';

/**
 * Renders the WelcomeView displaying the background and Outmind logo
 * @param props Props used to render the WelcomeView
 */
export const WelcomeView: React.FC<WelcomeViewProps> = ({ children, withFooter }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.backgroundContainer} container>
      <Grid item md={4} xs={12}>
        <Media query="(max-width: 959px)">
          {(matches) => (
            <div className={!matches ? classes.bannerContainer : classes.bannerContainerMobile}>
              <div>{!matches ? <OutmindLogo large white /> : <OutmindLogo white />}</div>
            </div>
          )}
        </Media>
      </Grid>
      <Grid item md={8} xs={12}>
        <Media query="(max-width: 959px)">
          {(matches) => (
            <Card className={!matches ? classes.mainWrapper : classes.mainWrapperMobile}>
              <CardContent className={!matches ? classes.cardContent : classes.cardContentMobile}>
                {children}
                {withFooter ? (
                  <div
                    className={!matches ? classes.footerContainer : classes.footerContainerMobile}
                  >
                    <Footer />
                  </div>
                ) : null}
              </CardContent>
            </Card>
          )}
        </Media>
      </Grid>
    </Grid>
  );
};

interface WelcomeViewProps {
  withFooter?: boolean;
}
