import React from 'react';
import Media from 'react-media';
import { useTranslations } from '../../hooks';
import { Typography } from '../../material';
import { useStyles } from './styles';

/**
 * Displays the Outmind footer on the Signin Page
 */
export const Footer: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <Media query="(max-width: 959px)">
      {(matches) => (
        <div className={classes.footer}>
          <a className={classes.footerLink} href="https://www.outmind.fr">
            <Typography
              className={!matches ? classes.footerLinkText : classes.footerLinkTextMobile}
            >
              {`© ${new Date().getFullYear()} Outmind`}
            </Typography>
          </a>
          <span className={classes.footerLink}>
            <Typography
              className={!matches ? classes.footerLinkText : classes.footerLinkTextMobile}
            >
              |
            </Typography>
          </span>
          <a
            className={classes.footerLink}
            href="https://www.outmind.fr/cgu"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography
              className={!matches ? classes.footerLinkText : classes.footerLinkTextMobile}
            >
              {t('terms_and_conditions')}
            </Typography>
          </a>
          <a
            className={classes.footerLink}
            href="https://www.outmind.fr/data-privacy"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography
              className={!matches ? classes.footerLinkText : classes.footerLinkTextMobile}
            >
              {t('privacy_policy_uf')}
            </Typography>
          </a>
          <a
            className={classes.footerLink}
            href="https://www.outmind.ai/disclosure"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography
              className={!matches ? classes.footerLinkText : classes.footerLinkTextMobile}
            >
              Disclosure
            </Typography>
          </a>
        </div>
      )}
    </Media>
  );
};
