import { makeStyles } from '../../material';

const skeletonTitleLogoStyle = {
  padding: '3px',
};

export const useStyles = makeStyles(
  {
    bookmarkSkeletonContainer: {
      display: 'flex',
      padding: '8px 24px',
    },
    skeletonContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 24px 16px 24px',
    },
    skeletonContent: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '5px',
    },
    skeletonDate: {
      display: 'flex',
      margin: 'auto',
    },
    skeletonMain: {
      width: '65%',
    },
    skeletonThumbnail: {
      display: 'flex',
      padding: '3px',
    },
    skeletonTitle: {
      alignItems: 'center',
      display: 'flex',
    },
    skeletonTitleSourceLogo: {
      ...skeletonTitleLogoStyle,
      marginLeft: '5px',
    },
    skeletonTitleText: {
      paddingLeft: '12px',
    },
    skeletonTitleTypeLogo: skeletonTitleLogoStyle,
    starSkeleton: {
      color: '#dedcdc',
    },
  },
  { index: 1 },
);
