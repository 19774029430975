import { useTheme } from '../../../../hooks';
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      seeAllButton: {
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '0.875rem',
        marginLeft: '20px',
        padding: '5px 0 5px 0',
      },
      seeAllButtonArrow: {
        marginTop: '2px',
      },
    },
    { index: 1 },
  )();
};
