export const enTranslation = {
  '14day_free_trial': '14-day free trial',
  accept_terms_helper: "I accept Outmind's {{- termsOfServiceLink}} and {{- privacyPolicyLink}}.",
  accounts: 'Accounts',
  add: 'Add',
  add_label: 'Add label',
  add_source: 'Add a source',
  add_source_classic_helper:
    'To connect a new source, click on the corresponding tool. You will be redirected to enter your credentials.',
  add_source_dialog_sub_title: 'You will be able to add other sources later.',
  add_source_dialog_title: 'Add a new source',
  add_source_dialog_title_onboarding: 'Begin by adding your first source',
  add_source_login_password_helper:
    'To connect this new source, please fill in your credentials below.',
  add_source_onboarding_helper:
    'Start by connecting your first source by clicking on one of the available tools below. You will be redirected to fill in your credentials. You will be able to add other sources later.',
  add_source_security_helper:
    'When you connect a new tool, your data remains protected. All your data is encrypted at rest and while in transit on Outmind.',
  add_tag: 'Add tag',
  added: 'Added',
  admin: 'Admin',
  admin_consent_successfully_granted:
    'Admin consent has been successfully granted for your organization.',
  admin_setting: 'Administrator Setting',
  administrator: 'Administrator',
  after: 'After',
  all_my_emails: 'All my emails',
  and_x_other_persons: ' and {{count}} other person',
  and_x_other_persons_plural: ' and {{count}} other persons',
  anytime: 'Anytime',
  app_crash_helper_text: 'If this happens again, please contact support.',
  archived_conversations: 'Archived conversations',
  at_least_n_other_documents: 'At least {{count}} other documents',
  available_short: 'Available',
  before: 'Before',
  box_elements: 'files',
  box_items: 'folders',
  box_new_items: 'new folders',
  by: 'by:',
  cancel: 'Cancel',
  cancel_configuration: 'Would you like to cancel the setup of your new source?',
  cancel_configuration_helper:
    'If you choose to cancel the setup of your new source, it will be completely deleted.',
  cancel_configuration_helper_plural:
    'If you choose to cancel the setup of your new sources, they will be completely deleted.',
  cancel_configuration_plural: 'Would you like to cancel the setup of your new sources?',
  caution: 'Caution!',
  characters_exceeded: '{{maxCharacters}} characters are exceeded',
  check_if_file_is_stored_somehere_else:
    'Please check if this document is not stored on an inaccessible device (removable disk, other computer, etc.).',
  choose_color: 'Choose your Color',
  choose_synced_items: 'Choose the items to synchronize',
  click_here: 'Click here',
  click_here_to_install: 'Click here to install',
  click_if_no_popup: 'Click here if no pop-up opens.',
  click_if_no_redirect: 'Click here if you are not redirected.',
  click_to_access_source:
    'Click to access this source documents which were made available to you by your organization',
  click_to_reconnect: 'Click to reconnect',
  coming_soon: 'Coming soon',
  company_code: 'Company code',
  complete_your_information: 'Fill in your information',
  complete_your_information_helper: 'One more step before you can access Outmind',
  configure_source: 'Set up your new source',
  configure_source_plural: 'Set up your new sources',
  confirm: 'Confirm',
  connect_later: 'Connect later',
  connect_sources_invite: 'Your organization has invited you to connect your sources',
  connecting_extension: 'Connecting to the extension...',
  connection_in_progress: 'Connection in progress...',
  connector_creation_failed:
    'An error occurred while creating your source. Try again in a few moments and contact support if the problem persists.',
  connector_name: 'Connector name',
  contact_person: 'Contact {{displayName}}',
  contact_support: 'Contact support',
  continue_configuration: 'Continue setup',
  continue_with_google: 'Continue with Google',
  continue_with_microsoft: 'Continue with Microsoft',
  create: 'Create',
  create_account: 'Create my account',
  create_account_helper:
    'To create your Outmind account, start by signing in with your business account on Google or Microsoft.',
  create_new_tag: 'Create a new Tag',
  create_one: 'create one.',
  create_tag: 'Create Tag',
  create_user: 'Create new user',
  created: 'Created ',
  creating_new_source_in_progress: 'Creation of the new source in progress...',
  credentials_update_failed: 'Re-establishing connection with {{source}} is failed',
  credentials_update_success:
    '{{source}} connection with Outmind has been successfully re-established.',
  crm_account_annual_revenue: 'Annual Revenue',
  crm_account_industry: 'Industry',
  crm_account_owner: 'Owner',
  crm_opportunity_account: 'Account',
  crm_opportunity_amount: 'Amount',
  crm_opportunity_close_date: 'Close Date',
  crm_opportunity_owner: 'Owner',
  crm_opportunity_status: 'Status',
  cta_google_workspace: 'Add all Google Workspace',
  cta_microsoft_365: 'Add all Microsoft 365',
  custom: 'Custom',
  data_loading_helper: 'You will find them here as soon as your first source is synchronized.',
  date_newest_oldest: 'Date (newest to oldest)',
  date_oldest_newest: 'Date (oldest to newest)',
  decommissioned: 'Decommissioned',
  delete: 'Delete',
  delete_source: 'Delete source',
  delete_source_helper: "Don't worry, you can always add sources later if you change your mind.",
  delete_source_plural: 'Delete sources',
  delete_tag_caution_helper: 'It will also be deleted for all your coworkers.',
  delete_tag_helper: 'It will be removed from all documents on which it has been added.',
  delete_tag_title: 'Are you sure you want to delete this tag?',
  deletion_started:
    'The deletion of this source has begun. We will notify you once your data has been completely deleted.',
  desktop: 'Desktop',
  did_you_mean: 'Did you mean:',
  document_tags: 'Document tags',
  download_desktop: 'Download Outmind App',
  download_desktop_helper:
    'With the Outmind app, you will only be one Ctrl-Space away from all your documents.',
  download_extension: 'Download extension',
  download_extension_helper:
    'Enjoy an optimal search experience on Outmind: download the extension for your browser.',
  downloads: 'Downloads',
  dropbox_elements: 'files',
  dropbox_items: 'folders',
  dropbox_new_items: 'new folders',
  edit_a_tag: 'Edit a tag',
  edit_tag: 'Edit  tag',
  edit_user: 'Edit user',
  edited: 'Edited ',
  email: 'Email',
  end: 'End',
  enjoy_free_trial: 'Enjoy my free trial',
  enjoy_free_trial_helper:
    'You will be redirected to the payment interface. You will be able to unsubscribe at any time, and no fees will be charger to your account before the end of your 14-day trial period.',
  extension_onboarding_helper:
    'You made the right choice! By installing the Outmind extension on your browser, you have instantly access to the knowledge of all your documents, wherever they are.',
  extension_onboarding_title:
    'You have successfully installed the Outmind extension for your browser',
  field_address: 'Address',
  field_agency: 'Agency',
  field_author: 'Author',
  field_boardName: "Board's name",
  field_commercialName: "Salesman's name",
  field_consultantName: "Consultant's name",
  field_content: 'Content',
  field_creator: 'Creator',
  field_customerName: "Client's name",
  field_internalReferenceNumber: 'Internal reference number',
  field_manager: 'Manager',
  field_modelName: 'Model',
  field_parents: 'File tree',
  field_person: 'Person',
  field_receiver: 'Recipient',
  field_sender: 'Sender',
  field_title: 'Title',
  finished: 'Finished',
  first_name: 'First name',
  folders: 'Folders',
  for_more_information: 'for more information',
  formats: 'Formats',
  go_back_to_outmind: 'Go back to Outmind',
  group_messages: 'Group messages',
  hi: 'Hi',
  history_label: 'History',
  i_have_vip_code: 'I have a VIP access code',
  if_already_signed_up: 'If you already have an account, ',
  if_no_redirect: 'If you have not been redirected',
  if_not_signed_up: "If you don't have an account yet, ",
  in: 'in:',
  in_progress: 'IN PROGRESS',
  in_uf: 'In "{{name}}"',
  index_error: 'An error occurred regarding {{index}} search. Some results can be missing.',
  'index_name_*': 'All',
  index_name_accounts: 'Accounts',
  index_name_cards: 'Cards',
  index_name_construction_sites: 'Construction sites',
  index_name_construction_tools: 'Construction tools',
  index_name_contacts: 'Contacts',
  index_name_files: 'Files',
  index_name_mails: 'Emails',
  index_name_messages: 'Messages',
  index_name_missions: 'Missions',
  index_name_opportunities: 'Opportunities',
  index_name_pages: 'Pages',
  index_name_people: 'Teammates',
  installed: 'Installed',
  job_role: 'Position / Job role',
  keyboard_shortcut: 'Keyboard shortcut',
  keyboard_shortcut_helper: 'Choose the keyboard shortcut of your choice to access Outmind.',
  last_days: 'Last {{days}} days',
  last_edit: 'Last edit ',
  lead: 'Lead',
  learn_more: 'Learn more',
  less: 'Less',
  localdisk: 'Local disk',
  localdisk_out_of_scope:
    "These elements are from a file server. If you wish to synchronise them, you'll have to create a Network Disk source",
  localfile_with_outmind_app_only: 'This document cannot be opened from your web browser.',
  login_name: 'Login',
  login_verb: 'Sign in',
  login_with: 'Sign in with',
  lost_auth: 'Connection lost with {{source}}',
  lost_auth_helper_text:
    'To keep your Outmind documents up-to date, you’ll need to re-establish connection with source {{source}} ({{email}}).',
  manage_sources: 'Manage sources',
  missions_sold_on_this_subject: 'Missions on this subject sold by {{displayName}}',
  missions_staffed_on_this_subject: 'Missions on this subject where {{displayName}} was staffed',
  model: 'Model',
  more: 'More',
  my_browser_extensions: 'My browser extensions',
  my_drive: 'My Drive',
  my_favorite_files: 'My favorite files',
  my_vip_access_code: 'My VIP access code',
  name: 'Name',
  name_connector: 'Name your connector',
  need_authorization: 'You need a valid authorization to access this resource.',
  nest_tag: 'Nest this new tag in an existing one',
  nest_tag_with_children: "You can't nest tag with children",
  networkdisk: 'Network disk',
  networkdisk_out_of_scope:
    "These elements are from a local disk. If you wish to synchronise them, you'll have to create a Local Disk source",
  new_data_search_refreshed:
    'Your search results have been refreshed due to new items detected in one of your sources.',
  new_tab_extension: 'New Tab Extension',
  new_tab_extension_helper: 'Replace your new tab with the Outmind homepage',
  new_tag_created: 'A new tag has been created',
  new_tag_input: 'Name your new tag',
  new_tag_input_placeholder: "Tag's name",
  new_update_available: 'A new update is available',
  next_checkup_date: 'Next checkup',
  no_access: 'You do not have access to this document',
  no_content: 'No content',
  no_data_yet: "You don't have any data yet",
  no_data_yet_helper: 'Synchronize a first source to retrieve your data here.',
  no_date: 'No date',
  no_email: 'No email',
  no_files_in_favorites: 'No files in your favorites',
  no_items_found: 'No items found for this query.',
  no_items_found_helper: 'Try with other keywords or expand the search parameters.',
  no_notification: 'No notification available',
  no_object: 'no object',
  no_phone: 'No phone',
  no_preview_found: 'No preview available for this document',
  no_preview_found_helper: 'Please consult document into dedicated source.',
  no_source_connected: 'No source connected',
  no_source_connected_helper: 'Click below to connect your first source.',
  no_suggestions_helper: 'No suggestions available. Run the search for more results.',
  no_syncable_elements: 'No syncable elements',
  no_syncable_folders: 'No folder selected',
  no_tag_found: 'No tag found or this name is already attached to this document',
  no_tags_found: 'No tags found on this document',
  no_teams: 'No Teams',
  not_installed: 'Not installed',
  notifications: 'Notifications',
  on_x_day: 'on {{date}}',
  one_moment_please: 'One moment please...',
  one_source_syncing: 'One of your sources is being synchronized.',
  onedrive_elements: 'files',
  onedrive_items: 'folders',
  onedrive_new_items: 'new folders',
  open: 'Open',
  open_desktop: 'Open Outmind app',
  open_in_outmind_app: 'Open search in Outmind',
  open_in_source: 'Open in source',
  open_with_file_explorer: 'Open with file explorer',
  other_tags_associated: 'Other tags associated to this document',
  out_of_order_short: 'Out of order',
  outlook_elements: 'emails',
  outlook_items: 'maiboxes',
  outlook_new_items: 'new mailboxes',
  outmind_dekstop: 'Outmind Desktop Application',
  outmind_extension: 'Outmind Extension',
  outmind_newtab_extension: 'Outmind New Tab Extension',
  page_not_found_helper_text: 'Sorry, the page you were looking for could not be found.',
  password: 'Password',
  person_creator_of: '{{displayName}} has created at least {{count}} document on this subject',
  person_creator_of_plural:
    '{{displayName}} has created at least {{count}} documents on this subject',
  person_responsible_of: '{{displayName}} has sold at least {{count}} mission on this subject',
  person_responsible_of_plural:
    '{{displayName}} has sold at least {{count}} missions on this subject',
  person_worked_on:
    '{{displayName}} has been staffed in at least {{count}} mission on this subject',
  person_worked_on_plural:
    '{{displayName}} has been staffed in at least {{count}} missions on this subject',
  persons_documents_on_this_subject: 'Documents by {{displayName}} on this subject',
  please_remove_out_of_scope_folders_localdisk: 'Please remove the items from your computer.',
  please_remove_out_of_scope_folders_networkdisk: 'Please remove the items from your file server.',
  position_at_account: '{{position}} at {{accountName}}',
  prefer_web_app: 'I prefer using the web app',
  preview: 'Preview',
  privacy_helper_text:
    'Outmind only accesses your data for reading. Data is encrypted when it travels through our services and when it is stored.',
  privacy_policy: 'privacy policy',
  privacy_policy_uf: 'Privacy Policy',
  privacy_reminder_content_1:
    'it will be indexed but not stored. The index is completely encrypted.',
  privacy_reminder_content_2: 'not even the Outmind team nor your collaborators.',
  privacy_reminder_content_3: 'you have the right to delete them at any moment.',
  privacy_reminder_title: 'We respect your privacy',
  privacy_reminder_title_1: 'Outmind has no access to your data',
  privacy_reminder_title_2: 'No one but you can read your emails/messages or unshared data',
  privacy_reminder_title_3: 'Your data belong to you',
  privacy_scope_helper_text:
    'Outmind never asks for more permissions from your tools than it needs to function properly.',
  private_channels: 'Private channels',
  private_messages: 'Private messages',
  protected_data: 'Your data is protected',
  public_channels: 'Public channels',
  purchase_outmind_single: 'I want to purchase Outmind for me',
  purchase_outmind_team: 'I want to purchase Outmind for my team',
  real_time_syncing: 'Real-time synchronization',
  really_delete_source:
    'Do you really want to remove the source {{sourceTypeName}} "{{sourceName}}"?',
  reconnect: 'Reconnect',
  redirection_helper: "You'll be redirected in a few moments...",
  refresh: 'Refresh',
  register_view_title: "Hey there, we don't know you yet.",
  register_view_title_subtitle: "Tell us more about what you're looking for.",
  related_to: 'Related to',
  relevance: 'Relevance',
  reload: 'Reload',
  reload_the_page: 'Reload the page',
  rename: 'Rename',
  reset: 'Reset',
  restart: 'Restart',
  results_count: '({{total}} result)',
  results_count_plural: '({{total}} results)',
  return: 'Return',
  root_folder: 'All the files',
  save: 'Save',
  search: 'Search',
  search_by_person: 'Search all the information related to this person',
  search_failed: 'An error has occurred, please try your search again.',
  search_in: 'Search in',
  search_in_folder: 'Search in this folder',
  search_instead_for: 'Search instead for',
  search_only_in: 'Search only in',
  search_option: 'Search for an option',
  secured: 'Secured',
  see_all_results: 'See all results',
  see_all_shared_items: 'See all shared items',
  see_all_similar_results: 'See all similar documents to {{documentName}}',
  see_faq: 'See FAQ',
  see_more: 'See more',
  see_our: 'See our',
  select_folders: 'Add folders',
  select_or_create_tag: 'Select a tag or create one:',
  sent: 'Sent ',
  serial_number: 'Serial n°',
  share: 'Share',
  share_my_source: 'Share this source to all my collaborators on Outmind (read only)',
  share_source_helper: 'Share this source to all your collaborators on Outmind (read only)?',
  share_this_source: 'Share this source',
  shared_by: 'Shared by:',
  shared_files: 'Shared files',
  shared_to_your_team: 'Shared with your team',
  sharepoint_elements: 'folders et files',
  sharepoint_items: 'drives',
  sharepoint_new_items: 'new drives',
  show_in_finder: 'Show in Finder',
  show_preview: 'Show Preview',
  show_tags: 'Show tags',
  showing_results_for: 'Showing results for',
  signin_helper: 'Sign in to continue',
  signout: 'Sign out',
  similar_results: 'Similar results',
  slack_elements: 'messages',
  slack_items: 'conversations',
  slack_new_items: 'new conversations',
  something_went_wrong: 'An unknown error happened.',
  sort_by: 'Sort by',
  source_deletion_success: 'Your {{- sourceName }} source has been completely removed.',
  source_disabled:
    "For this source type, you'll have to activate it via outmind's customer support.",
  source_management: 'Source management',
  source_management_helper: 'Find here the sources you have already synchronized.',
  source_not_available_in_web:
    'This connector is not available from the browser. Please use the desktop application.',
  source_shared_success: 'Your source has been shared with your organization',
  sources: 'Sources',
  sources_access:
    'Access to the following sources has been made available to you by your administrator.',
  spotlight_instruction:
    'You can also press {{- commandHtml}} to open Outmind anywhere on the web.',
  spotlight_instruction_onboarding: 'Press {{- commandHtml }} to open Outmind.',
  start: 'Start',
  start_sync_failed:
    'An error occurred while launching the synchronization. Try again in a few moments or contact support if the problem persists.',
  start_synchronization: 'Start synchronization',
  suggestions_label: 'Suggestions',
  sync_discovered_items: 'Synchronize all future items (recommended)',
  sync_discovered_popper:
    'By default, all the {{elements}} in the selected {{items}} are synchronized by Outmind. The synchronization of all future items will result in syncing {{newItems}} in {{source}}',
  synced_fu: 'Synced',
  synced_l: 'synced',
  synchronization_in_progress: 'Synchronization in progress...',
  syncing: 'Syncing',
  tag_cycle_validator: 'Can not associate the same tag as a parent',
  tag_deleted: 'Your tag has been deleted',
  tag_first_parent_validator: 'Please select a first level parent',
  tag_name_validator: 'Please specify the name of the tag',
  tag_parent_validator: "Please select the tag's parent",
  tag_same_name_error: 'A tag with the same name already exists',
  tag_updated: 'Your tag has been updated',
  team_tags: 'Team tags',
  teams_elements: 'conversations',
  teams_items: 'teams',
  teams_new_items: 'new teams',
  terms_and_conditions: 'General Terms and Conditions',
  terms_of_service: 'terms of service',
  tip: 'Tip',
  tip_enter: 'Enter to validate',
  tip_navigate: 'Navigate',
  trello_elements: 'cards',
  trello_items: 'boards',
  trello_new_items: 'new boards',
  try_it_now: 'Try it now!',
  type_folder: 'Folders',
  type_folder_extended: 'Folders',
  type_mail_archive: 'Archives',
  type_mail_archive_extended: 'PST, MSG',
  type_media: 'Medias',
  type_media_extended: 'Medias',
  type_pdf: 'PDF',
  type_pdf_extended: 'PDF',
  type_presentation: 'Slides',
  type_presentation_extended: 'Powerpoint, Google Slides, ...',
  type_sheets: 'Sheets',
  type_sheets_extended: 'Excel, Google Sheets, ...',
  type_text: 'Texts',
  type_text_extended: 'Word, Google Docs, ...',
  type_unknown: 'Other',
  type_unknown_extended: 'Other',
  types: 'Types',
  unable_to_access_document: 'Unable to access this document',
  unauthorized_access: "You don't have access to the desired resource.",
  unavailable_short: 'Unavailable',
  update_latest_version: 'Install latest app update',
  use_outmind_app_for_localfiles:
    'To access documents stored locally on your device, please use the Outmind app.',
  users: 'Users',
  we_are_creating_your_new_source: 'We are creating your new source.',
  we_are_trying_to_log_you_in: 'We are trying to log you in.',
  we_are_trying_to_log_you_in_please_wait: 'We are trying to log you in. Please wait...',
  we_are_updating_your_o365_connectors: 'We are updating your Office 365 connectors...',
  welcome_newtab_helper: 'What are you looking for today?',
  welcome_on_outmind: 'Welcome to Outmind',
  x_days: '{{count}} day',
  x_days_ago: '{{count}} day ago',
  x_days_ago_plural: '{{count}} days ago',
  x_days_plural: '{{count}} days',
  x_hours: '{{count}} hour',
  x_hours_ago: '{{count}} hour ago',
  x_hours_ago_plural: '{{count}} hours ago',
  x_hours_plural: '{{count}} hours',
  x_minutes: '{{count}} minute',
  x_minutes_ago: '{{count}} minute ago',
  x_minutes_ago_plural: '{{count}} minutes ago',
  x_minutes_plural: '{{count}} minutes',
  x_seconds: '{{count}} second',
  x_seconds_ago: '{{count}} second ago',
  x_seconds_ago_plural: '{{count}} seconds ago',
  x_seconds_plural: '{{count}} seconds',
  you_can_close_this_window: 'You can close this window.',
  you_have_been_successfully_logged_in: 'You have been logged in.',
  you_will_be_redirected: 'You will soon be redirected',
  your_data_is_coming: 'Your data is coming!',
  your_new_source_is_now_created: 'Your source has been created.',
  your_o365_connectors_have_been_updated: 'Your Office 365 connectors have been updated',
};
