import React from 'react';
import { Typography } from '../../material';
import { DocumentDate } from './DocumentDate';
import { useStyles } from './styles';

/**
 * Displays header for Message Components (Message Component, SearchResultMessage Component)
 */
export const MessageHeader: React.FC<MessageHeaderProps> = ({ messageDate, messageFrom }) => {
  const classes = useStyles();

  const parseFromHeader = (fromHeader: string): string => fromHeader.split(/ <.+>/)[0];

  const messageSender = messageFrom ? parseFromHeader(messageFrom) : undefined;

  return (
    <div className={classes.singleMessageHeader}>
      <Typography variant="body2">{messageSender}</Typography>
      <div className={classes.singleMessageDateContainer}>
        <DocumentDate date={messageDate} />
      </div>
    </div>
  );
};

interface MessageHeaderProps {
  messageDate: number;
  messageFrom: string;
}
