import { makeStyles } from '../../material';

const useStyles = makeStyles(
  {
    helperContainer: {
      alignItems: 'center',
      backgroundColor: '#eeeeee',
      color: '#888888',
      display: 'flex',
      flexGrow: 1,
      height: 50,
      justifyContent: 'center',
      margin: '-12px 0 8px 0',
      position: 'relative',
    },
    helperText: {
      marginRight: '4px',
    },
    helperTextContainer: {
      padding: '12px',
    },
    hideHelperButton: {
      position: 'absolute',
      right: '20px',
    },
    refreshButton: {
      color: 'inherit',
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: 'inherit',
      textDecoration: 'underline',
      textTransform: 'none',
      width: '120px',
    },
  },
  { index: 1 },
);

export { useStyles };
