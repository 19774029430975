import { createMuiTheme } from '../../material';

export interface CustomTheme {
  colors: {
    active: {
      main: string;
    };
    aux: {
      main: string;
    };
    background: {
      main: string;
    };
    border: {
      main: string;
    };
    default: {
      main: string;
    };
    disabled: {
      main: string;
    };
    hover: {
      main: string;
    };
    primary: {
      main: string;
    };
    secondary: {
      main: string;
    };
    separator: {
      main: string;
    };
    slider: {
      main: string;
    };
    text: {
      main: string;
    };
  };
  paddings: {
    title: string;
  };
}

declare module '@material-ui/core/styles' {
  interface Theme {
    designSystem: CustomTheme;
  }
  interface ThemeOptions {
    designSystem: CustomTheme;
  }
}

export const theme = createMuiTheme({
  designSystem: {
    colors: {
      active: {
        main: '#245EE5',
      },
      aux: {
        main: '#8E8E8E',
      },
      background: {
        main: '#F6F6F6',
      },
      border: {
        main: '#E5E5E5',
      },
      default: {
        main: '#1E1E1E',
      },
      disabled: {
        main: '#BFBFBF',
      },
      hover: {
        main: '#245ee517',
      },
      primary: {
        main: '#1E1E1E',
      },
      secondary: {
        main: '#5C5C5C',
      },
      separator: {
        main: '#EAEDF6',
      },
      slider: {
        main: '#EFEFEF',
      },
      text: {
        main: '#545E6A',
      },
    },
    paddings: {
      title: '12px',
    },
  },
  palette: {
    primary: {
      main: '#245EE5',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
});
