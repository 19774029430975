import { SearchableDocuments } from '@outmind/types';
import React, { memo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  useDisplayedResultsNumber,
  useSearch,
  useSpellingSuggestionsIsFetching,
} from '../../../hooks';
import { Grid } from '../../../material';
import { DocumentSkeleton } from '../../DocumentSkeleton';
import { NoResultsBackground } from '../../DocumentsListBackgrounds';
import { ResultsList } from '../../ResultsList';
import { SearchErrorBanner } from '../../SearchErrorBanner';

const ClassicTabNP: React.FC<ClassicTabProps> = ({ scrollableTarget, tab }) => {
  const { results, hasNextPage, fetchNextPage, isLoading, isError } = useSearch(tab);

  const isSpellingSuggestionsFetching = useSpellingSuggestionsIsFetching();

  const displayedResultsNumber = useDisplayedResultsNumber(tab);

  if (results.length === 0 && !isLoading && !isSpellingSuggestionsFetching) {
    return (
      <>
        <SearchErrorBanner index={tab} show={isError} />
        <NoResultsBackground />
      </>
    );
  }

  const documentSkeletons = [
    // Display 3 rows of skeletons elements
    ...Array(3 * displayedResultsNumber),
    // eslint-disable-next-line react/no-array-index-key
  ].map((_e, i) => <DocumentSkeleton key={i} index={tab} />);

  const content =
    (isLoading && results.length === 0) || isSpellingSuggestionsFetching ? (
      documentSkeletons
    ) : (
      <ResultsList results={results} tab={tab} />
    );

  const contentContainer =
    tab === 'people' || tab === 'contacts' || tab === 'pages' ? (
      <Grid container>{content}</Grid>
    ) : (
      content
    );

  return (
    <InfiniteScroll
      dataLength={results.length}
      hasMore={!!hasNextPage}
      loader={<DocumentSkeleton index={tab} />}
      next={fetchNextPage}
      scrollableTarget={scrollableTarget}
      scrollThreshold={0.7}
    >
      <>
        <SearchErrorBanner index={tab} show={isError} />
        {contentContainer}
      </>
    </InfiniteScroll>
  );
};

interface ClassicTabProps {
  scrollableTarget: HTMLDivElement | null;
  tab: SearchableDocuments;
}

export const ClassicTab = memo(ClassicTabNP);
