import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    navigationButton: {
      '&:last-child': {
        paddingBottom: '20px',
      },
      alignItems: 'center',
      display: 'flex',
      padding: '20px',
      width: '500px',
    },
    navigationButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    navigationButtonIcon: {
      color: '#eee',
    },
    navigationButtonIconContainer: {
      height: '52px',
      width: '52px',
    },
    navigationButtonLink: {
      textDecoration: 'none',
    },
    navigationButtonText: {
      color: '#333',
      flex: '1',
      fontSize: '1.125rem',
      paddingLeft: '20px',
      textAlign: 'left',
    },
  },
  { index: 1 },
);
