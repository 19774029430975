import { ConnectorSyncState } from '@outmind/types';
import React, { useState } from 'react';

import { useFeatureFlags, useTranslations } from '../../../hooks';
import {
  DeleteIcon,
  GroupAddIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreHorizIcon,
  RefreshIcon,
} from '../../../material';
import { SyncProgress } from '../../SyncProgress';
import { useStyles } from './styles';

export const ManageSourceMenu: React.FC<ManageSourceMenuProps> = ({
  deleteDisabled,
  refreshDisabled,
  onDelete,
  onShare,
  onRefresh,
  isRefreshing,
  shareDisabled,
  syncState,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslations();
  const { withSharedConnectors } = useFeatureFlags();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleAction = (action: () => void): void => {
    action();
    handleClose();
  };

  return (
    <div className={classes.iconButtonContainer}>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-label="more"
        classes={{ root: classes.moreOptionButton }}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuContainer }}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem disabled={refreshDisabled || isRefreshing} onClick={onRefresh}>
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            {isRefreshing && syncState ? (
              <div className={classes.syncProgressContainer}>
                <SyncProgress
                  duration={syncState.duration}
                  progression={syncState.progression}
                  size={20}
                  status={syncState.status}
                />
              </div>
            ) : (
              <RefreshIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={t('refresh')} />
        </MenuItem>
        {withSharedConnectors ? (
          <MenuItem
            disabled={deleteDisabled || shareDisabled}
            onClick={() => handleAction(onShare)}
          >
            <ListItemIcon classes={{ root: classes.itemIcon }}>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText primary={t('share')} />
          </MenuItem>
        ) : null}
        <MenuItem disabled={deleteDisabled} onClick={() => handleAction(onDelete)}>
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            <DeleteIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={t('delete')} primaryTypographyProps={{ color: 'secondary' }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ManageSourceMenuProps {
  deleteDisabled?: boolean;
  isRefreshing: boolean;
  onDelete: () => void;
  onRefresh: () => void;
  onShare: () => void;
  refreshDisabled: boolean;
  shareDisabled: boolean;
  syncState?: ConnectorSyncState;
}
