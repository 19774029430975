/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DocumentIndicesExtended } from '@outmind/types';

import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';

const selectedTabBaseStyle = {
  fontWeight: 500,
};

const selectedTabColor: Record<DocumentIndicesExtended, string> = {
  '*': '#245EE5',
  accounts: '#329866',
  cards: '#0079BF',
  construction_sites: '#DC8250',
  construction_tools: '#7E63CB',
  contacts: '#235895',
  files: '#5B84E3',
  mails: '#E45D4D',
  messages: '#57A179',
  missions: '#7C4513',
  opportunities: '#E7BE56',
  pages: '#F29C38',
  people: '#8E3F7D',
  slides: '#F29C38',
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      indexLabel: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '14px',
      },
      indexTabAccountIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.accounts,
        },
        color: selectedTabColor.accounts,
        fill: selectedTabColor.accounts,
      },
      indexTabAllIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor['*'],
        },
        color: selectedTabColor['*'],
        fill: selectedTabColor['*'],
      },
      indexTabCardIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.cards,
        },
        color: selectedTabColor.cards,
        fill: selectedTabColor.cards,
      },
      indexTabConstructionSitesIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.construction_sites,
        },
        color: selectedTabColor.construction_sites,
        fill: selectedTabColor.construction_sites,
      },
      indexTabConstructionToolsIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.construction_tools,
        },
        color: selectedTabColor.construction_tools,
        fill: selectedTabColor.construction_tools,
      },
      indexTabContactIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.contacts,
        },
        color: selectedTabColor.contacts,
        fill: selectedTabColor.contacts,
      },
      indexTabFileIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.files,
        },
        color: selectedTabColor.files,
        fill: selectedTabColor.files,
      },
      indexTabIcon: {
        color: theme.designSystem.colors.aux.main,
        fill: 'inherit',
        fontSize: '22px',
        padding: '8px 8px 0 0',
      },
      indexTabLabelContainer: {
        '&:not(.Mui-selected):hover': {
          color: theme.designSystem.colors.secondary.main,
          fill: theme.designSystem.colors.secondary.main,
        },
        flexBasis: 'auto',
        minWidth: 'unset',
      },
      indexTabMailIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.mails,
        },
        color: selectedTabColor.mails,
        fill: selectedTabColor.mails,
      },
      indexTabMessageIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.messages,
        },
        color: selectedTabColor.messages,
        fill: selectedTabColor.messages,
      },
      indexTabMissionIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.missions,
        },
        color: selectedTabColor.missions,
        fill: selectedTabColor.missions,
      },
      indexTabOpportunityIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.opportunities,
        },
        color: selectedTabColor.opportunities,
        fill: selectedTabColor.opportunities,
      },
      indexTabPageIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.pages,
        },
        color: selectedTabColor.pages,
        fill: selectedTabColor.pages,
      },
      indexTabPersonIcon: {
        ...selectedTabBaseStyle,
        '&#ActiveTabIndicator': {
          backgroundColor: selectedTabColor.people,
        },
        color: selectedTabColor.people,
        fill: selectedTabColor.people,
      },
      indexTabRoot: {
        minHeight: 55,
      },
      indexTabWrapper: {
        '&>*:first-child': { marginBottom: '8px !important' },
        alignItems: 'center',
        color: 'inherit',
        display: 'flex',
        fill: 'inherit',
        flexDirection: 'row',
        fontSize: '14px',
        justifyContent: 'center',
        textTransform: 'capitalize',
        width: '100%',
      },
      searchTotalLabel: {
        marginLeft: '8px',
      },
      tabIndicator: { borderRadius: '26px', height: '2px' },
      tabs: {
        color: theme.designSystem.colors.aux.main,
        fill: theme.designSystem.colors.aux.main,
        width: '100%',
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    { index: 1 },
  )();
};
