/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

export const useStyles = () =>
  makeStyles(
    {
      iconContainer: {
        marginRight: '12px',
      },
      notificationContainer: {
        '&:hover': {
          backgroundColor: '#f5f7fa',
        },
        backgroundColor: '#f9f9f9',
        borderRadius: '10px',
        cursor: 'pointer',
        display: 'flex',
        margin: '15px 20px',
        minHeight: '52px',
      },
      notificationInnerContainer: {
        alignItems: 'center',
        display: 'flex',
        padding: '8px 24px',
      },
      updateAvailableText: { color: '#2C65BA', fontWeight: 600 },
      updateAvailableTextButton: {
        color: '#8E8E8E',
        cursor: 'pointer',
      },
    },
    { index: 1 },
  )();
