import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    tabInput: {
      borderRadius: 15,
      height: '55px',
      width: '100%',
    },
    tabRoot: {
      color: '#4E86EB',
      textAlign: 'center',
    },
    tabs: {
      width: '100%',
    },
    tabSelect: {},
  },
  { index: 1 },
);
