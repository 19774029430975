import { sourcesProvideIndex } from '@outmind/helpers';
import { DocumentIndicesExtended } from '@outmind/types';
import React from 'react';

import { indexOrder } from '../../../config';
import { useConnectors, useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { FormControl, InputBase, MenuItem, Select } from '../../../material';
import { useSelector } from '../../../store';
import { useStyles } from './styles';

export const MobileTabs: React.FC<MobileTabsProps> = ({ onChangeTypeTab }) => {
  const chosenIndexTab = useSelector((s) => s.search.chosenIndexTab);

  const { data: connectors = [] } = useConnectors();

  const classes = useStyles();

  const { t } = useTranslations();

  const sources = connectors.map((connector) => connector.source);

  const handleChangeTab = (e: React.ChangeEvent<{ name?: string; value: unknown }>): void =>
    onChangeTypeTab(e, e.target.value as DocumentIndicesExtended);

  const mobileTabsIndexElements = indexOrder.map((indexTab) => {
    if (indexTab !== '*' && !sourcesProvideIndex(sources, indexTab)) {
      return null;
    }

    return <MenuItem value={indexTab}>{t(`index_name_${indexTab}` as TranslationKey)}</MenuItem>;
  });

  return (
    <FormControl className={classes.tabs} variant="outlined">
      <Select
        classes={{
          root: classes.tabRoot,
          select: classes.tabSelect,
        }}
        input={<InputBase className={classes.tabInput} id="tab-selected" name="index" />}
        onChange={handleChangeTab}
        value={chosenIndexTab}
        variant="outlined"
      >
        {mobileTabsIndexElements}
      </Select>
    </FormControl>
  );
};

interface MobileTabsProps {
  onChangeTypeTab: (_: React.ChangeEvent<unknown>, index: DocumentIndicesExtended) => void;
}
