import { DocumentIndicesExtended } from '@outmind/types';
import React from 'react';

import { Actions, useDispatch } from '../../store';
import { DisplayElementsResponsive } from '..';
import { DesktopTabs } from './DesktopTabs';
import { MobileTabs } from './MobileTabs';

/**
 * IndexTabs displays the tabs used to filter on DocTypology in ResultsList
 */
export const IndexTabs: React.FC<IndexTabsProps> = ({ resetScroll }) => {
  const dispatch = useDispatch();

  const onChangeTypeTab = (
    _: React.ChangeEvent<unknown>,
    typeTab: DocumentIndicesExtended,
  ): void => {
    resetScroll();
    dispatch(Actions.setChosenIndexTab(typeTab));
  };

  const MobileView = <MobileTabs onChangeTypeTab={onChangeTypeTab} />;
  const DesktopView = <DesktopTabs onChangeTypeTab={onChangeTypeTab} />;

  return <DisplayElementsResponsive computer={DesktopView} mobile={MobileView} />;
};

interface IndexTabsProps {
  resetScroll: () => void;
}
