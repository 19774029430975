import { ExtendedSources, Source } from '@outmind/types';
import React from 'react';
import { useTranslations } from '../../../hooks';

import { ButtonBase, Card, CardContent, Grid, Typography } from '../../../material';
import { ConnectorLogos } from '../../ConnectorLogos';
import { useStyles } from './styles';
import googleIcon from '../../../assets/google.png';

export const AddGSuiteButton: React.FC<AddGSuiteButtonProps> = ({
  setChosenSource,
  setShowAddSourceConfirmationDialog,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <Grid item md={6} sm={12}>
      <Card className={classes.oneClickCard}>
        <ButtonBase
          className={classes.sourceButton}
          onClick={() => {
            setChosenSource('gsuite');
            setShowAddSourceConfirmationDialog(true);
          }}
        >
          <CardContent className={classes.oneClickCardContent}>
            <div className={classes.oneClickIconsContainer}>
              <div className={classes.googleLogoContainer}>
                <img alt="" className={classes.sourceLogo} src={googleIcon} />
              </div>
              <div className={classes.sourceMiniLogoContainer}>
                <img alt="" className={classes.sourceLogo} src={ConnectorLogos[Source.Drive]} />
              </div>
              <div className={classes.sourceMiniLogoContainer}>
                <img alt="" className={classes.sourceLogo} src={ConnectorLogos[Source.Gmail]} />
              </div>
            </div>
            <div className={classes.oneClickTextContainer}>
              <Typography className={classes.oneClickTextMain} variant="subtitle2">
                {t('cta_google_workspace')}
              </Typography>
            </div>
          </CardContent>
        </ButtonBase>
      </Card>
    </Grid>
  );
};

interface AddGSuiteButtonProps {
  setChosenSource: (source: ExtendedSources) => void;
  setShowAddSourceConfirmationDialog: (bool: boolean) => void;
}
