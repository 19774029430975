import React from 'react';
import Media from 'react-media';

export const DisplayElementsResponsive: React.FC<DisplayElementsResponsiveProps> = ({
  mobile,
  computer,
  mobileClassName,
}) => {
  const mobileDisplay = mobileClassName ? <div className={mobileClassName}>{mobile}</div> : mobile;

  return (
    <Media query="(max-width: 959px)">{(matches) => (!matches ? computer : mobileDisplay)}</Media>
  );
};

interface DisplayElementsResponsiveProps {
  computer: React.ReactNode;
  mobile: React.ReactNode;
  mobileClassName?: string;
}
