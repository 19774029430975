import { FeatureFlags } from '@outmind/types';
import { useState } from 'react';

import { useUserProfile } from '../authn';

/**
 * Hook used to know which feature flags are activated in this environment
 */
export const useFeatureFlags = (): Partial<FeatureFlags> => {
  const { data: user } = useUserProfile();

  const [withAmosit] = useState(process.env['REACT_APP_WITH_AMOSIT'] === 'true');
  const [withDocumentLabels] = useState(process.env['REACT_APP_WITH_DOCUMENT_LABELS'] === 'true');
  const [withLocalDisk] = useState(process.env['REACT_APP_WITH_LOCALDISK'] === 'true');
  const [withNetworkDisk] = useState(process.env['REACT_APP_WITH_FILESERVER'] === 'true');
  const [withSalesForce] = useState(process.env['REACT_APP_WITH_SALESFORCE'] === 'true');
  const [withFilePreview] = useState(process.env['REACT_APP_WITH_FILE_PREVIEW'] === 'true');
  const [withSharedConnectors] = useState(
    process.env['REACT_APP_WITH_SHARED_CONNECTORS'] === 'true',
  );
  const [withVSA] = useState(process.env['REACT_APP_WITH_VSA'] === 'true');
  const [withRUM] = useState(process.env['REACT_APP_WITH_RUM'] === 'true');

  return {
    withAmosit,
    withDocumentLabels,
    withFilePreview,
    withLocalDisk,
    withNetworkDisk,
    withRUM,
    withSalesForce,
    withSharedConnectors,
    withVSA,
    ...(user?.featureFlags ?? {}),
  };
};
