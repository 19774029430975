import { Source } from '@outmind/types';

import { useFeatureFlags } from '../useFeatureFlags';

export const useAvailableSources = (): Response$UseAvailableSources => {
  const { withAmosit, withSalesForce, withLocalDisk, withNetworkDisk, withVSA } = useFeatureFlags();

  const disabledSources: Source[] = [];

  const sources = Object.values(Source).filter((source): boolean => {
    if (source === Source.Amosit) return withAmosit ?? false;
    if (source === Source.Salesforce) return withSalesForce ?? false;
    if (source === Source.LocalDisk) {
      if (!withLocalDisk) {
        disabledSources.push(Source.LocalDisk);
      }
      return true;
    }
    if (source === Source.NetworkDisk) {
      if (!withNetworkDisk) {
        disabledSources.push(Source.NetworkDisk);
      }
      return true;
    }
    if (source === Source.VSA) return withVSA ?? false;
    return true;
  });

  const betaSources = [
    Source.Amosit,
    Source.Salesforce,
    Source.LocalDisk,
    Source.NetworkDisk,
    Source.VSA,
  ];

  return { betaSources, disabledSources, sources };
};

interface Response$UseAvailableSources {
  betaSources: Source[];
  disabledSources: Source[];
  sources: Source[];
}
