import React from 'react';
import { ChevronLeft, Crown, User, Users } from 'tabler-icons-react';
import { useSignOut, useTranslations } from '../../hooks';
import { Avatar, Grid, IconButton } from '../../material';
import { BaseNavigationButton } from '../BaseNavigationButton';
import { WelcomeView } from '../WelcomeView';
import { useStyles } from './styles';

export const RegisterView: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { mutate: signOut } = useSignOut();

  return (
    <WelcomeView>
      <IconButton className={classes.navigationPreviousButton} onClick={signOut}>
        <ChevronLeft />
      </IconButton>
      <div className={classes.navigationTitle}>{t('register_view_title')}</div>
      <div className={classes.navigationSubtitle}>{t('register_view_title_subtitle')}</div>
      <Grid container spacing={3}>
        <Grid className={classes.navigationButtonContainer} item xs={12}>
          <BaseNavigationButton
            icon={
              <Avatar className={classes.navigationButtonIconMe}>
                <User color="#fff" size={32} />
              </Avatar>
            }
            target="_blank"
            text={t('purchase_outmind_single')}
            to={{ pathname: 'https://outmind.chargebee.com/hosted_pages/plans/single-user-plan' }}
          />
        </Grid>
        <Grid className={classes.navigationButtonContainer} item xs={12}>
          <BaseNavigationButton
            icon={
              <Avatar className={classes.navigationButtonIconTeam}>
                <Users color="#fff" size={32} />
              </Avatar>
            }
            target="_blank"
            text={t('purchase_outmind_team')}
            to={{ pathname: 'https://www.outmind.ai/price' }}
          />
        </Grid>
        <Grid className={classes.navigationButtonContainer} item xs={12}>
          <BaseNavigationButton
            icon={
              <Avatar className={classes.navigationButtonIconVip}>
                <Crown color="#fff" size={32} />
              </Avatar>
            }
            text={t('i_have_vip_code')}
            to="/register/vip"
          />
        </Grid>
      </Grid>
    </WelcomeView>
  );
};
