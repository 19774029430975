import { forwardRef, memo, MutableRefObject, PropsWithChildren, useEffect } from 'react';

import { Grid } from '../../../material';
import { useSelector } from '../../../store';
import { DisplayElementsResponsive } from '../../DisplayElementsResponsive';
import { DocumentPreview } from '../../DocumentPreview';
import { IndexTabs } from '../../IndexTabs';
import { SourcesStillSyncingHelper } from '../../SourcesStillSyncingHelper';
import { SpellingSuggestion } from '../../SpellingSuggestion';
import { useStyles } from './styles';

const DocumentsListWrapperNP = forwardRef<HTMLDivElement, DocumentsListWrapperProps>(
  ({ children, id, inSpotlight }, ref) => {
    const classes = useStyles({ inSpotlight });

    const resetScroll = (): void => {
      if (ref) (ref as MutableRefObject<HTMLDivElement>).current.scrollTo(0, 0);
    };

    const { infos: previewInfos, isOpened } = useSelector((s) => s.previews);
    const queryId = useSelector((s) => s.search.queryId);

    // We need to reset the scroll position when the queryId changes
    useEffect(() => resetScroll(), [queryId]);

    const result = (
      <div className={classes.documentsListContainer}>
        <div className={classes.indexTabsContainer}>
          <IndexTabs resetScroll={resetScroll} />
        </div>
        <Grid className={classes.documentsListSubContainer} container>
          <Grid
            ref={ref}
            className={classes.documentsListInnerContainer}
            id={id}
            item
            xs={isOpened ? 6 : 12}
          >
            <SourcesStillSyncingHelper />
            <SpellingSuggestion />
            {children}
          </Grid>
          {isOpened && !!previewInfos ? (
            <Grid className={classes.documentPreviewContainer} item xs={6}>
              <DocumentPreview />
            </Grid>
          ) : null}
        </Grid>
      </div>
    );

    return (
      <DisplayElementsResponsive
        computer={result}
        mobile={result}
        mobileClassName={classes.inResponsiveView}
      />
    );
  },
);

type DocumentsListWrapperProps = PropsWithChildren<{
  id?: string;
  inSpotlight?: boolean;
}>;

export const DocumentsListWrapper = memo(DocumentsListWrapperNP);
