import React, { useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ChevronRight } from 'tabler-icons-react';
import { ButtonBase, Card, CardContent, Typography } from '../../material';
import { useStyles } from './styles';

export const BaseNavigationButton: React.FC<BaseNavigationButtonProps> = ({
  icon,
  text,
  ...rest
}) => {
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link className={classes.navigationButtonLink} {...rest}>
      <ButtonBase onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Card elevation={isHovered ? 4 : 2}>
          <CardContent className={classes.navigationButton}>
            {icon}
            <Typography className={classes.navigationButtonText}>{text}</Typography>
            <ChevronRight color="#ccc" size={40} />
          </CardContent>
        </Card>
      </ButtonBase>
    </Link>
  );
};

interface BaseNavigationButtonProps extends LinkProps {
  icon: JSX.Element;
  text: string;
}
