/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

export const useStyles = () =>
  makeStyles(
    {
      allTabContainer: {
        display: 'block',
        position: 'relative',
        width: '100%',
      },
    },
    { index: 1 },
  )();
