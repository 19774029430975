import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  (theme) => ({
    accountContainer: {
      '&:hover': {
        backgroundColor: '#f5f7fa',
        cursor: 'pointer',
      },
      borderBottom: '1px solid #eee',
      color: '#222222',
      display: 'block',
      padding: '16px 24px',
      textDecoration: 'none',
    },
    accountInfo: {
      alignItems: 'center',
      display: 'flex',
    },
    accountInfos: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    accountInfoText: {
      color: '#666',
      fontSize: '0.875rem',
      overflow: 'hidden',
      padding: '0 4px',
      'svg&': {
        height: '21px',
        minHeight: '21px',
        minWidth: '21px',
        width: '21px',
      },
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    accountInnerContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    accountLogo: {
      '& svg': {
        width: '100%',
      },
      height: '21px',
      margin: '4px',
      minHeight: '21px',
      minWidth: '21px',
      width: '21px',
    },
    accountMetasInnerContainer: {
      marginTop: '4px',
    },
    accountWebsite: {
      '& a': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& svg': {
        height: '21px',
        minHeight: '21px',
        minWidth: '21px',
        width: '21px',
      },
      color: theme.palette.primary.main,
      fontSize: '0.875rem',
      overflow: 'hidden',
      padding: '0 4px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    flex: {
      display: 'flex',
    },
  }),
  { index: 1 },
);
