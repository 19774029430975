import React, { memo, useState } from 'react';

import { Label, useAllLabels, useTranslations } from '../../../hooks';
import { AddIcon, Chip } from '../../../material';
import { AddLabelMenu } from '../AddLabelMenu';
import { useStyles } from './styles';

const AddLabelButtonNP: React.FC<AddLabelButtonProps> = ({
  addLabel,
  contextMenuButton,
  createNewLabel,
  documentLabels,
  menuPosition,
}) => {
  const classes = useStyles();

  const { data: allLabels = {} } = useAllLabels();

  const otherLabels = Object.values(allLabels).filter(
    (label) => !documentLabels.find((documentLabel) => label.id === documentLabel.id),
  );

  const { t } = useTranslations();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = (e?: React.MouseEvent<HTMLDivElement>): void => {
    e?.preventDefault();
    e?.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className="addLabelButton" style={{ opacity: contextMenuButton ? '100' : '0' }}>
      {contextMenuButton ? (
        <div aria-hidden="true" onClick={onClick}>
          {contextMenuButton}
        </div>
      ) : (
        <Chip
          classes={{
            colorPrimary: classes.colorPrimary,
            iconSmall: classes.iconSmall,
            root: classes.chipRoot,
          }}
          color="primary"
          icon={<AddIcon />}
          label={t('add_tag')}
          onClick={onClick}
          size="small"
          variant="outlined"
        />
      )}
      {anchorEl ? (
        <AddLabelMenu
          addLabel={addLabel}
          anchorEl={anchorEl}
          createNewLabel={createNewLabel}
          documentLabels={documentLabels}
          labels={otherLabels}
          menuPosition={menuPosition}
          onClose={onClose}
        />
      ) : null}
    </div>
  );
};

interface AddLabelButtonProps {
  addLabel: (label: Label) => void;
  contextMenuButton?: JSX.Element;
  createNewLabel: (labelName: string) => void;
  documentLabels: Label[];
  menuPosition?: { mouseX: number; mouseY: number };
}

export const AddLabelButton = memo(AddLabelButtonNP);
