/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    link: {
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
      color: '#1565c0',
      textDecoration: 'none',
    },
  },
  { index: 1 },
);
