import { makeStyles } from '../../../material';

const sourceLogoContainerStyle = {
  alignItems: 'center',
  display: 'flex',
  height: 35,
  justifyContent: 'center',
  margin: 'auto',
  width: 35,
};

export const useStyles = makeStyles(
  (theme) => ({
    credentialsDialog: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    credentialsDialogButton: {
      marginTop: 10,
      width: '100%',
    },
    credentialsDialogInput: {
      marginTop: 10,
      width: '100%',
    },
    googleLogoContainer: {
      ...sourceLogoContainerStyle,
      height: 32,
      margin: '6px',
      width: 32,
    },
    oneClickCard: {
      border: '1px solid #EDEDED',
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.08)',
      boxSizing: 'border-box',
      height: 106,
    },
    oneClickCardContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      width: '100%',
    },
    oneClickIconsContainer: {
      display: 'flex',
    },
    oneClickTextContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    },
    oneClickTextMain: {
      color: '#666',
      fontWeight: 600,
      paddingTop: 4,
    },
    oneClickTextSub: {
      color: '#666',
    },
    sourceButton: {
      '&:hover': {
        backgroundColor: '#eeeeee',
      },
      '-ms-transition': 'background-color 150ms linear',
      '-webkit-transition': 'background-color 150ms linear',
      height: '100%',
      transition: 'background-color 150ms linear',
      width: '100%',
    },
    sourceButtonBeta: {
      '&::after': {
        bottom: '5px',
        color: '#ffffff',
        content: '"beta"',
        fontSize: '.75em',
        padding: '3px',
        position: 'absolute',
        right: 0,
        transform: 'rotate(-45deg)',
        zIndex: 100,
      },
      '&::before': {
        background: theme.palette.grey[700],
        bottom: '5px',
        content: '""',
        height: '1.2rem',
        position: 'absolute',
        right: '-45px',
        transform: 'rotate(-45deg)',
        width: '100%',
      },
    },
    sourceButtonDisabled: {
      backgroundColor: '#eeeeee',
      height: '100%',
      width: '100%',
    },
    sourceCard: {
      border: '1px solid #EDEDED',
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.08)',
      boxSizing: 'border-box',
      height: 106,
    },
    sourceLogo: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    sourceLogoBigContainer: {
      ...sourceLogoContainerStyle,
      height: 120,
      width: 120,
    },
    sourceLogoContainer: sourceLogoContainerStyle,
    sourceMiniLogoContainer: {
      ...sourceLogoContainerStyle,
      height: 35,
      margin: '5px',
      width: 35,
    },
    sourceName: {
      color: '#666',
      fontWeight: 600,
      paddingTop: 4,
      textAlign: 'center',
    },
  }),
  { index: 1 },
);
