import { makeStyles } from '../../material';

const signinHelperBasic = {
  color: '#757575',
  fontSize: '1.125rem',
  marginBottom: 20,
  marginTop: 30,
  maxWidth: '450px',
  textAlign: 'center' as const,
};

const signinTitleBasic = {
  color: '#757575',
  fontSize: '2rem',
  textAlign: 'center' as const,
};

export const useStyles = makeStyles(
  {
    acceptTerms: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 10,
    },
    acceptTermsHelper: {
      fontSize: '0.75rem',
    },
    continueButton: {
      marginTop: 30,
      width: 300,
    },
    continueButtonProgress: {
      left: '50%',
      marginLeft: '-12px',
      marginTop: '-12px',
      position: 'absolute',
      top: '50%',
    },
    navigationPreviousButton: {
      left: '12px',
      position: 'absolute',
      top: '12px',
    },
    signinHelper: signinHelperBasic,
    signinHelperMobile: {
      ...signinHelperBasic,
      fontSize: '1rem',
    },
    signinTitle: signinTitleBasic,
    signinTitleMobile: {
      ...signinTitleBasic,
      fontSize: '1.5rem',
    },
    userInfosCard: {
      borderRadius: 10,
      marginTop: 20,
      padding: 10,
    },
    userInfosEmailInput: {
      width: '390px',
    },
    userInfosFamilyNameInput: {
      margin: '8px',
      maxWidth: '150px',
    },
    userInfosGivenNameInput: {
      margin: '8px',
      maxWidth: '150px',
    },
    userMainInfosContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    userSubInfosContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    vipAccessCodeInput: {
      margin: '8px -4px 0 -4px',
    },
    vipAccessCodeInputContainer: {
      marginTop: '24px',
    },
    vipAccessCodeTitle: {
      color: '#666',
    },
  },
  { index: 1 },
);
