import { SearchMessagesResult } from '@outmind/types';
import clsn from 'classnames';
import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';

import { Avatar } from '../../material';
import { DocumentContent } from './DocumentContent';
import { File } from './File';
import { MessageHeader } from './MessageHeader';
import { useStyles } from './styles';

/**
 * Displays an `ElasticMessage` document from a SearchMessagesResult
 */
export const SearchResultMessage: React.FC<SearchResultMessageProps> = ({
  fullContent,
  highlightedMessage,
  result,
}) => {
  const { document: message, highlights: bestMessageHighlights } = result;

  const classes = useStyles();

  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (message.id === highlightedMessage && messageRef?.current) {
      messageRef.current.scrollIntoView();
    }
  }, [highlightedMessage]);

  const parseFromHeader = (fromHeader: string): string => fromHeader.split(/ <.+>/)[0];

  const messageSender = message.from ? parseFromHeader(message.from) : undefined;

  const displayedBestMessageContent = fullContent
    ? message.content
    : bestMessageHighlights?.content ?? message.summary;

  const { attachments } = message;
  const attachmentElms = attachments.map((att) => (
    <File key={att.document.id} displayAsAttachment result={att} />
  ));

  const parsedContent = (
    <ReactMarkdown
      components={{
        h1: 'div',
        h2: 'div',
        h3: 'div',
        h4: 'div',
        h5: 'div',
        h6: 'div',
        p: 'div',
        pre: ({ node, ...props }) => <pre style={{ whiteSpace: 'break-spaces' }} {...props} />,
      }}
      linkTarget="_blank"
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[gfm]}
    >
      {displayedBestMessageContent ?? ''}
    </ReactMarkdown>
  );

  return (
    <div
      ref={messageRef}
      className={clsn(
        classes.singleMessage,
        message.id === highlightedMessage ? 'highlighted' : undefined,
      )}
    >
      <Avatar className={classes.singleMessageAvatar} variant="rounded">
        {messageSender?.[0]}
      </Avatar>
      <div className={classes.singleMessageContent}>
        <MessageHeader messageDate={message.date} messageFrom={message.from} />
        <DocumentContent content={ReactDOMServer.renderToString(parsedContent)} />
        {attachmentElms.length ? (
          <div className={classes.messageAttachmentsContainer}>{attachmentElms}</div>
        ) : null}
      </div>
    </div>
  );
};

interface SearchResultMessageProps {
  fullContent?: boolean;
  highlightedMessage?: string;
  result: SearchMessagesResult;
}
