import React, { useState, useEffect } from 'react';
import { LabelTypes } from '@outmind/types';
import { IconButton, StarOutlineRoundedIcon, StarRoundedIcon } from '../../material';
import { useStyles } from './styles';
import {
  useAddLabel,
  useCreateLabel,
  useUserProfile,
  useRemoveLabel,
  getBookmarkLabel,
} from '../../hooks';

export const Bookmark: React.FC<BookmarkProps> = ({ documentId, bookmarked }) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(bookmarked);

  const { data: user } = useUserProfile();

  const { mutateAsync: addLabel } = useAddLabel();

  const { mutateAsync: removeLabel } = useRemoveLabel();

  const { mutateAsync: createLabel } = useCreateLabel();

  useEffect(() => {
    setIsActive(bookmarked);
  }, [bookmarked]);

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    if (!isActive) bookmarkFile();
    else removeBookmark();
  };

  const bookmarkFile = async (): Promise<void> => {
    setIsActive(true);
    const { color, id, name, type } = getBookmarkLabel(user?.id);
    addLabel({ documentId, labelId: id, type: LabelTypes.Bookmark });
    await createLabel({
      label: {
        color,
        id,
        name,
        type,
      },
    });
  };

  const removeBookmark = async (): Promise<void> => {
    setIsActive(false);
    const labelId = `${user?.id}-bookmark`;
    removeLabel({ documentId, labelId });
  };

  return (
    <IconButton aria-label="bookmark" className={classes.bookmarkIconButton} onClick={handleClick}>
      {isActive ? (
        <StarRoundedIcon className={classes.bookmarkIcon} />
      ) : (
        <StarOutlineRoundedIcon className={classes.bookmarkEmptyIcon} />
      )}
    </IconButton>
  );
};

interface BookmarkProps {
  bookmarked?: boolean;
  documentId: string;
}
