import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    bookmarkEmptyIcon: {
      color: '#B4B4B4',
    },
    bookmarkIcon: {
      color: '#F9CB6A',
    },
    bookmarkIconButton: {
      marginLeft: '6px',
      padding: '2px',
    },
  },
  { index: 1 },
);
