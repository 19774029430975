import clsn from 'classnames';
import React from 'react';
import { useStyles } from './styles';

/**
 * LabeledBox display a rounded box labeled with a title
 */
export const LabeledBox: React.FC<LabeledBoxProps> = (props) => {
  const { children, classes, title } = props;

  const defaultClasses = useStyles();

  const boxContainerClassName = clsn(classes?.boxContainer, defaultClasses.boxContainer);

  const borderBoxClassName = clsn(classes?.borderBox, defaultClasses.borderBox);

  const boxTitleClassName = clsn(classes?.boxTitle, defaultClasses.boxTitle);

  return (
    <div className={boxContainerClassName}>
      <div className={borderBoxClassName}>
        <span className={boxTitleClassName}>{title}</span>
        {children}
      </div>
    </div>
  );
};

interface LabeledBoxProps {
  classes?: {
    borderBox?: string;
    boxContainer?: string;
    boxTitle?: string;
  };
  title?: string;
}
